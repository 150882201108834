@use '../../includes' as *;

.search_pagination_direction {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $g8;
  transition: color var(--duration-half) ease-in;
  border: 0;
  background-color: transparent;
  color: $fog;
  font-weight: $bold;

  &:hover,
  &:focus {
    color: $pebble;
    cursor: pointer;
  }

  svg {
    width: 6px;
    height: 10px;
    transform: translate(0, 1px);
    fill: currentcolor;
  }
}
