@use '../../../components/includes' as *;

.text_input {
  position: relative;
  max-width: $max-width;
  margin-block-start: $g24;
  margin-inline: var(--spacing-inline);
  color: $midnight;

  svg,
  path {
    fill: $white;
  }

  label {
    // hides the label, but screenreaders still pick it up
    @include visuallyhidden;
  }

  @include breakpoint($sm) {
    margin-block: $g24 var(--spacing-block);
    margin-inline: var(--spacing-inline);
  }
}

.search_filter_input {
  @include h2;

  width: 100%;
  height: 40px;
  margin-block-end: $g32;
  margin-inline-end: $g12;
  padding: $g8 $g12 $g8 $g16;
  border: 1px solid $white;
  border-radius: 7.2px;
  background-color: transparent;
  color: var(--text-color);
  font-size: $f12;

  @include breakpoint($sm) {
    height: 58px;
    padding-inline-start: $g24;
    border-width: 2px;
    font-size: $f16;
  }

  @include breakpoint($md) {
    font-size: $f26;
  }

  @include breakpoint($lg) {
    height: 80px;
  }

  &::placeholder {
    color: $pebble;
  }

  &:focus {
    outline: 3px solid var(--focus-color);
  }
}

.text_input_icon {
  position: absolute;
  inset-block-start: 13px;
  inset-inline-end: 13px;
  width: 15px;
  height: 15px;

  @include breakpoint($sm) {
    inset-block-start: 18px;
    inset-inline-end: 18px;
    width: 24px;
    height: 24px;
  }

  @include breakpoint($lg) {
    inset-block-start: 22px;
    inset-inline-end: 22px;
    width: 34px;
    height: 34px;
  }
}

.text_input_clear_button {
  position: absolute;
  inset-block-start: 13px;
  inset-inline-end: 13px;
  width: 15px;
  height: 15px;
  padding: 0;
  border: none;
  background: transparent;

  &:focus {
    outline: 1px solid var(--focus-color);
  }

  @include breakpoint($sm) {
    inset-block-start: 15px;
    inset-inline-end: 15px;
    width: 30px;
    height: 30px;
  }

  @include breakpoint($lg) {
    inset-block-start: 20px;
    inset-inline-end: 20px;
    width: 40px;
    height: 40px;
  }
}
