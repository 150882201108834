@use '../../includes' as *;

.search_pagination_list_item_button {
  transition: color var(--duration-half) ease-in;
  border: 0;
  background-color: transparent;
  color: $fog;
  font-weight: $bold;

  &:hover,
  &:focus {
    color: $pebble;
    cursor: pointer;
  }
}

.search_pagination_list_item_button_selected {
  color: $pebble;
  font-weight: $normal;
}
