@use '../../includes' as *;

.search_pagination {
  display: flex;
  grid-area: pagination;
  justify-content: center;
  margin-block-end: var(--spacing-block);
  font-size: $f14;

  @include breakpoint($sm) {
    font-size: $f16;
  }
}
