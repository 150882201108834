@use '../../../components/includes' as *;

.search_filters {
  grid-area: filters;
  width: 100%;

  h3 {
    @include over-title($pebble);

    margin-block-end: $g28;
  }
}

.search_filters__item {
  all: unset;
  display: flex;
  flex-direction: column;
  gap: $g12;
  margin-block-end: $g28;

  @include breakpoint($sm) {
    gap: $g16;
  }

  label {
    @include body-copy-2;

    &:hover {
      color: $pebble;
    }
  }

  legend {
    @include over-title($yellow);

    margin-block-end: $g16;
  }
}

.radio {
  @include custom-radio;
}

.search_filters__reset_button {
  all: unset;

  @include body-copy-2;

  font-weight: $bold;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
    margin-inline-start: 0.75em;

    path {
      stroke-width: 2.5px;
    }
  }

  &:hover {
    color: $pebble;

    path {
      stroke: $pebble;
    }
  }
}
